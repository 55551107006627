import React from 'react';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import {setProducts, updateProduct} from "../../reducers/productReducer";
import {useDispatch, useSelector} from "react-redux";
import ProductService from "../../services/productService";
import BrandSelect from "../Brand/BrandSelect";
import CategorySelect from "./CategorySelect";

const ChangeProductValue = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [newValue, setNewValue] = React.useState('');
    const [newValueError, setNewValueError] = React.useState(false);
    const [newValueErrorMsg, setNewValueErrorMsg] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleSetNewValue = (event) => {
        setNewValue(event.target.value);
    };

    const clearFields = () => {
        setNewValue('');
    }

    const handleNewValueChange = () => {
        if (newValue !== '' || props.type === 'DELETE'){
            switch (props.type) {
                case 'NAME':
                    ProductService.changeName(props.product.productID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: {
                                        ...props.product,
                                        name: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'DESCRIPTION':
                    ProductService.changeDescription(props.product.productID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: {
                                        ...props.product,
                                        description: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'STATUS':
                    ProductService.changeStatus(props.product.productID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: {
                                        ...props.product,
                                        status: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'IS_FEATURED':
                    ProductService.changeIsFeatured(props.product.productID, newValue.toString()).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: {
                                        ...props.product,
                                        isFeatured: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'BRAND':
                    ProductService.changeBrand(props.product.productID, newValue.brandID).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: {
                                        ...props.product,
                                        brand: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'DELETE':
                    ProductService.changeStatus(props.product.productID, 'DELETED').then(response => {
                        if (response){
                            if (response.status === true){
                                console.log("**** Reloading Products ****")
                                ProductService.getAll().then((response) =>{
                                    if (response.status === true){
                                        dispatch(setProducts({
                                            products: response.results
                                        }));
                                    } else {
                                        // show error could not get data
                                    }
                                }).catch((err) => {
                                    console.log(err);
                                });
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                default:
                    break;
            }
        } else {
            showAlertMsg('Must enter a value to proceed with change!', 'error');
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFields();
    };

    const getChangeLabel = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    `You are about to change the name ${props.product.name}`
                );
            case 'DESCRIPTION':
                return (
                    `You are about to change the description ${props.product.description}`
                );
            case 'STATUS':
                return (
                    `You are about to change the product tag status of productID: ${props.product.productID} | Name: ${props.product.name}`
                );
            case 'BRAND':
                return (
                    `You are about to change the product brand of productID: ${props.product.productID} | Name: ${props.product.name}`
                );
            case 'IS_FEATURED':
                return (
                    `You are about to change the product featured status of productID: ${props.product.productID} | Name: ${props.product.name}`
                );
            case 'DELETE':
                return (
                    `You are about to DELETE a product productID: ${props.product.productID} | Name: ${props.product.name}`
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const getChangeContent = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Name"
                        fullWidth
                    />
                );
            case 'BRAND':
                return (
                    <BrandSelect required={true} disabled={false} brand={newValue} setBrand={setNewValue} />
                );
            case 'CATEGORY':
                return (
                    <CategorySelect isParent={true} category={newValue} setCategory={setNewValue} required={true} />
                );
            case 'DESCRIPTION':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        multiline
                        rows={4}
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New description"
                        fullWidth
                    />
                );
            case 'STATUS':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue('ARCHIVE')} disabled={((newValue !== '' ? newValue : props.product.status) === 'ARCHIVE') || props.product.status === 'DELETED'} >
                            Archive
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue('VISIBLE')} disabled={((newValue !== '' ? newValue : props.product.status) === 'VISIBLE') || props.product.status === 'DELETED'} >
                            Visible
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue('HIDDEN')} disabled={((newValue !== '' ? newValue : props.product.status) === 'HIDDEN') || props.product.status === 'DELETED'} >
                            Hidden
                        </Button>
                    </div>
                );
            case 'IS_FEATURED':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue(1)} disabled={((newValue !== '' ? newValue : props.product.status) === 1)} >
                            Feature
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue(0)} disabled={((newValue !== '' ? newValue : props.product.status) === 0)} >
                            Remove From Feature
                        </Button>
                    </div>
                );
            case 'DELETE':
                break;
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    return (
        <div>
            {props.type === 'DELETE' ?
                <Button variant="contained" disabled={props.product.status === 'DELETED'} color="error" size="small" onClick={handleClickOpen}>
                    Delete
                </Button>
                :
                <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} startIcon={<EditIcon />}>
                    Change
                </Button>
            }

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {props.type === 'DELETE' ?
                    <DialogTitle style={{color: 'red'}} id="form-dialog-title">!DELETING! Product</DialogTitle>
                    :
                    <DialogTitle id="form-dialog-title">Update Product Information</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity={alertSeverity}>{alertMsg}</Alert>
                        )}
                        {getChangeLabel()}
                    </DialogContentText>
                    {getChangeContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {props.type === 'DELETE' ?
                        <Button style={{marginLeft: "10px"}} variant="contained" color="error" onClick={handleNewValueChange}>
                            Delete
                        </Button>
                        :
                        <Button onClick={handleNewValueChange} color="primary">
                            Save
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangeProductValue;
