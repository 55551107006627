import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import ProductView from "./ProductView";
import CreateProduct from "./CreateProduct";
import Grid from "@mui/material/Grid";
import DataGridTable from "../DataGridTable";

const ProductTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const products = useSelector(state => state.productManager.products);

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            case "DELETED":
                return getText(languagePreference.lang, 'PCM21');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const dataGridOption = {
        rows: products || [],
        columns: [
            { field: 'productID', headerName: 'ID', hide: false, width: 50 },
            { field: 'name', headerName: 'Name', valueGetter: (params) => `${params.row.brand !== null && params.row.brand.name } | ${params.row.name}`, hide: false, width: 300 },
            { field: 'brand', headerName: 'Brand', valueGetter: (params) => `${params.row.brand !== null ? params.row.brand.name : "N/A"}`, hide: false, width: 160 },
            { field: 'category', headerName: 'Category', valueGetter: (params) => `${params.row.category !== null ? params.row.category.name : "N/A"}`, hide: false, width: 130 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row?.status), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 100,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <ProductView product={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.productID,
        onRowClick: () => {
            // params, event

        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreateProduct />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ProductTable;
