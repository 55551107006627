import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import {Alert, CardActions, CardMedia, CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {AlertTitle} from "@mui/lab";
import {setProducts, updateProduct} from "../../reducers/productReducer";
import GeneralStockInfoCreation from "./GeneralStockInfoCreation";
import StockAttributeInfoCreation from "./StockAttributeInfoCreation";
import ProductStockCreationReview from "./ProductStockCreationReview";
import StockService from "../../services/stockService";
import {useEffect} from "react";
import ProductService from "../../services/productService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateStock = (props) => {
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = React.useState(0);

    const [open, setOpen] = React.useState(false);

    const [price, setPrice] = React.useState(1);
    const [store, setStore] = React.useState('');
    const [quantity, setQuantity] = React.useState(1);
    const [metaTags, setMetaTags] = React.useState([]);
    const [selectedVariationValue, setSelectedVariationValue] = React.useState('1');
    const [selectedVariation, setSelectedVariation] = React.useState('');
    const [selectedProductVariation, setSelectedProductVariation] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setSelectedVariationValue('')
        setSelectedVariation('')
        // setQuantity('');
        setMetaTags([]);
        setStore('');
        // setPrice('');
    }

    const handleNext = () => {
        if (activeStep === 0){
            if (quantity === ''){
                showAlertMsg('Must enter total restock quantity!', 'error');
                return;
            }

            if (store === ''){
                showAlertMsg('Must select store where stock is located!', 'error');
                return;
            }

            if (selectedVariation === ''){
                showAlertMsg('Must select stock variation type!', 'error');
                return;
            }

            if (selectedVariationValue === ''){
                showAlertMsg('Must enter variation value!', 'error');
                return;
            }


            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        // if (activeStep === 1){
        //
        //
        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }

        if (activeStep === 1){
            let data = new FormData();
            data.append("product", JSON.stringify(props.product));
            data.append("quantity", quantity);
            data.append("price", price);
            data.append("store", JSON.stringify(store));
            data.append("metaTags", JSON.stringify(metaTags));
            data.append("variation", JSON.stringify({
                variation: selectedVariation,
                value: selectedVariationValue
            }));
            data.append("productVariation", (selectedProductVariation !== '' ? JSON.stringify(selectedProductVariation) : null));

            StockService.create(data).then(response => {
                if (response){
                    if (response.status === true){
                        console.log("**** Refreshing Products ****")
                        ProductService.getAll().then((response) =>{
                            if (response.status === true){
                                dispatch(setProducts({
                                    products: response.results
                                }));
                            } else {
                                // show error could not get data
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                        dispatch(updateProduct({
                            product: {
                                ...props.product,
                                stock: [
                                    ...props.product.stock,
                                    response.result
                                ]
                            }
                        }));
                        setDidReceivesResponse(true);
                        setDidSave(true);
                        showAlertMsg(response.message, 'success');
                        // clearFields();
                    } else {
                        setDidReceivesResponse(true);
                        setDidSave(false);
                        showAlertMsg(response.message, 'error');
                    }
                } else {
                    showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                }
            }).catch((err) => {
                console.log(err);
            });

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        clearFields();
        setDidReceivesResponse(false);
        setDidSave(false);
        setActiveStep(0);
    };

    const handleAddAttribute = (meta, value, isPresetValue) => {
        if (value !== ''){
            setMetaTags([
                ...metaTags,
                { meta, value, isPresetValue }
            ]);
        } else {
            showAlertMsg('Must enter a value for the meta tag.', 'error');
        }
    }

    const EndView = () => {
        return (
            (didReceivesResponse ?
                    (didSave ?
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                <strong>Product Stock Created</strong> — {alertMsg}
                            </Alert>
                            :
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                <strong>Product Stock Not Created </strong> — Unfortunately something went wrong in the backend
                                refresh the browser and try again. If you still are experiencing problems contact the IT department.
                            </Alert>
                    )
                    :
                    <CircularProgress color="primary" />
            )
        )
    };

    const steps = [
        {
            label: 'General Information',
            content: <GeneralStockInfoCreation
                selectedVariationValue={selectedVariationValue} setSelectedVariationValue={setSelectedVariationValue}
                selectedVariation={selectedVariation} setSelectedVariation={setSelectedVariation}
                selectedProductVariation={selectedProductVariation} setSelectedProductVariation={setSelectedProductVariation}
                quantity={quantity} setQuantity={setQuantity}
                store={store} setStore={setStore}
                price={price} setPrice={setPrice}
                product={props.product}
            />
        },
        // {
        //     label: 'Stock Attributes & Label Preview',
        //     content: <StockAttributeInfoCreation
        //         metaTags={metaTags} setMetaTags={setMetaTags}
        //         handleAddAttribute={handleAddAttribute}
        //     />
        // },
        {
            label: 'Review',
            content: <ProductStockCreationReview
                selectedVariationValue={selectedVariationValue} setSelectedVariationValue={setSelectedVariationValue}
                selectedVariation={selectedVariation} setSelectedVariation={setSelectedVariation}
                quantity={quantity} setQuantity={setQuantity}
                metaTags={metaTags} setMetaTags={setMetaTags}
                store={store} setStore={setStore}
                price={price} setPrice={setPrice}
            />
        },
        {
            label: 'Create',
            content: <EndView />
        }
    ];

    useEffect(() => {
        if ((props.productVariation !== null) && (props.productVariation !== undefined)){
            setSelectedProductVariation(props.productVariation)
        }
    }, []);

    return (
        <div>
            <Button variant="outlined" size={"small"} style={{marginLeft: 10, minWidth:175}} onClick={handleClickOpen}>
                Create Stock
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='xl'
                aria-labelledby="new-stock-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="new-stock-dialog-title" onClose={handleClose}>
                    Creating New Stock
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3, mb: 3 }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                return (
                                    <Step key={index} {...stepProps}>
                                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    {steps[activeStep].content}
                </DialogContent>
                <DialogActions>
                    {activeStep + 1 === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, mr: 2 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant={"contained"} onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant={"contained"} onClick={handleNext}>
                                    {activeStep + 1 === steps.length - 1 ? 'Create Stock' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateStock;
