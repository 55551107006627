import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// General Template for data tables. Requires configuration object.
const DataGridTable = (props) => {

    return (
        <Box sx={{ height: props.dataGridOption.height }}>
            <DataGrid {...props.dataGridOption} components={{ Toolbar: GridToolbar }} />
        </Box>
    );
};

export default DataGridTable;
