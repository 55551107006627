import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import CreateStore from "./CreateStore";
import StoreView from "./StoreView";
import Grid from "@mui/material/Grid";
import DataGridTable from "../DataGridTable";

const StoreTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const stores = useSelector(state => state.storeManager.stores);

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return getText(languagePreference.lang, 'AM23');
            case 0:
                return getText(languagePreference.lang, 'SM5');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const dataGridOption = {
        rows: stores || [],
        columns: [
            { field: 'storeID', headerName: 'ID', hide: false, width: 50 },
            { field: 'name', headerName: 'Name', valueGetter: (params) => `${params.row?.name}`, hide: false, width: 200 },
            { field: 'address', headerName: 'Address', valueGetter: (params) => `${params.row?.address ? params.row?.address : getText(languagePreference.lang,'AM26')}`, hide: false, width: 230 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row?.status), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 200,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <StoreView meta={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.storeID,
        onRowClick: () => {
            // params, event

        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    useEffect(() => {
        // if (stores.length <= 0){
        //     StoreService.getAll().then((response) =>{
        //         if (response.status === true){
        //             dispatch(setStores({
        //                 stores: response.stores
        //             }));
        //         } else {
        //             // show error could not get data
        //         }
        //     }).catch((err) => {
        //         console.log(err);
        //     });
        // }
    });

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreateStore />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default StoreTable;
