import React from 'react';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import MetaService from "../../services/metaService";
import {updateMeta} from "../../reducers/metaReducer";
import {useDispatch} from "react-redux";
import StockService from "../../services/stockService";
import {updateProduct} from "../../reducers/productReducer";


const ChangeStockValue = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [newValue, setNewValue] = React.useState(0);
    const [newValueError, setNewValueError] = React.useState(false);
    const [newValueErrorMsg, setNewValueErrorMsg] = React.useState('');

    const [voidReason, setVoidReason] = React.useState('');
    const [voidReasonError, setVoidReasonError] = React.useState(false);
    const [voidReasonErrorMsg, setVoidReasonErrorMsg] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleSetNewValue = (event) => {
        setNewValue(event.target.value);
    };

    const clearFields = () => {
        setNewValue('');
    }

    const getNewProductQty = () => {
        let newProduct = null;
        let newStockLst = [];
        for (let i = 0; i < props.product.stock.length; i++) {
            if (props.product.stock[i].stockID === props.stock.stockID){
                newStockLst.push({
                    ...props.product.stock[i],
                    available: newValue
                });
            } else {
                newStockLst.push(props.product.stock[i]);
            }
        }
        newProduct = {
            ...props.product,
            stock: newStockLst
        }
        return newProduct;
    }

    const getNewProductPrice = () => {
        let newProduct = null;
        let newStockLst = [];
        for (let i = 0; i < props.product.stock.length; i++) {
            if (props.product.stock[i].stockID === props.stock.stockID){
                newStockLst.push({
                    ...props.product.stock[i],
                    price: {
                        priceID: props.product.stock[i].price.priceID,
                        price: newValue,
                    }
                });
            } else {
                newStockLst.push(props.product.stock[i]);
            }
        }
        newProduct = {
            ...props.product,
            stock: newStockLst
        }
        return newProduct;
    }

    const getNewProductVoid = () => {
        let newProduct = null;
        let newStockLst = [];
        for (let i = 0; i < props.product.stock.length; i++) {
            if (props.product.stock[i].stockID === props.stock.stockID){
                newStockLst.push({
                    ...props.product.stock[i],
                    status: newValue,
                    voidReason: voidReason
                });
            } else {
                newStockLst.push(props.product.stock[i]);
            }
        }
        newProduct = {
            ...props.product,
            stock: newStockLst
        }
        return newProduct;
    }

    const handleNewValueChange = () => {
        if (newValue !== ''){
            switch (props.type) {
                case 'QTY':
                    StockService.changeQty(props.stock.stockID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: getNewProductQty()
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'VOID':
                    if (voidReason !== ''){
                        if (newValue === 'VOID'){
                            StockService.voidStock(props.stock.stockID, voidReason).then(response => {
                                if (response){
                                    if (response.status === true){
                                        dispatch(updateProduct({
                                            product: getNewProductVoid()
                                        }));
                                        showAlertMsg(response.message, 'success');
                                        clearFields();
                                        handleClose();
                                    } else {
                                        showAlertMsg(response.message, 'error');
                                    }
                                } else {
                                    showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            showAlertMsg("Must confirm stock void action.", 'error');
                        }
                    } else {
                        showAlertMsg("Must type void reason.", 'error');
                    }
                    break;
                case 'PRICE':
                    StockService.changePrice(props.stock.stockID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateProduct({
                                    product: getNewProductPrice()
                                }));
                                showAlertMsg(response.message, 'success');
                                clearFields();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                default:
                    break;
            }
        } else {
            showAlertMsg('Must enter a value to proceed with change!', 'error');
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFields();
    };

    const getChangeLabel = () => {
        switch (props.type) {
            case 'QTY':
                return (
                    `You are about to change the available quantity ${props.stock.available}`
                );
            case 'VOID':
                return (
                    `You are voiding a stock! Press Void and save to confirm change: ${props.stock.stockID}`
                );
            case 'PRICE':
                return (
                    `You are about to change the price ${props.stock.price.price}`
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const getChangeContent = () => {
        switch (props.type) {
            case 'QTY':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        type={"number"}
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={(event) => setNewValue(event.target.value)}
                        label="New Qty"
                        fullWidth
                    />
                );
            case 'VOID':
                return (
                    <div style={{textAlign: "center"}}>
                        <TextField
                            color={"primary"}
                            autoFocus
                            multiline
                            rows={4}
                            margin="dense"
                            id="voidReason"
                            value={voidReason}
                            error={voidReasonError} helperText={voidReasonErrorMsg} onChange={(event) => {setVoidReason(event.target.value)}}
                            label="Void Reason"
                            fullWidth
                        />
                        <Button style={{marginLeft: "10px"}} variant="contained" color={props.stock.status !== 'VOID' ? 'error' : 'primary'} onClick={() => setNewValue('VOID')} disabled={(newValue !== '' ? newValue : props.stock.status) === 'VOID'} >
                            Confirm Stock Void Action
                        </Button>
                    </div>
                );
            case 'PRICE':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        type={"number"}
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New Price"
                        fullWidth
                    />
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    return (
        <div>
            <Button disabled={props.stock.status === 'VOID'} variant={props.type === 'VOID' ? 'contained' : 'outlined'} color={props.type === 'VOID' ? 'warning' : 'primary'} size="small" onClick={handleClickOpen} startIcon={props.type !== 'VOID' && <EditIcon />}>
                {props.type === 'VOID' ? 'Void' : 'Change'}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Stock Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity={alertSeverity}>{alertMsg}</Alert>
                        )}
                        {getChangeLabel()}
                    </DialogContentText>
                    {getChangeContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNewValueChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangeStockValue;
