import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import CreateBrand from "./CreateBrand";
import BrandView from "./BrandView";
import DataGridTable from "../DataGridTable";
import Grid from "@mui/material/Grid";

const StoreTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const brands = useSelector(state => state.brandManager.brands);

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return getText(languagePreference.lang, 'AM23');
            case 0:
                return getText(languagePreference.lang, 'SM5');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    useEffect(() => {
        // if (brands.length <= 0){
        //     BrandService.getAll().then((response) =>{
        //         if (response.status === true){
        //             dispatch(setBrands({
        //                 brands: response.results
        //             }));
        //         } else {
        //             // show error could not get data
        //         }
        //     }).catch((err) => {
        //         console.log(err);
        //     });
        // }
    });

    const dataGridOption = {
        rows: brands || [],
        columns: [
            { field: 'brandID', headerName: 'Id', hide: false, width: 50 },
            { field: 'name', headerName: 'Name', valueGetter: (params) => `${params.row?.name}`, hide: false, width: 200 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row.status), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 200,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <BrandView brand={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.brandID,
        onRowClick: () => {
            // params, event
        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreateBrand />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default StoreTable;
