import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";

const ErrorView = () => {
    const navigate = useNavigate();

    const initData = {
        heading: "404",
        text: "The page you are looking for might have been removed or is temporarily unavailable.",
        btnText: "Go to Homepage"
    }

    useEffect(() => {

    },[]);

    return (
        <div className="inner inner-pages">
            <div className="errorViewOverlay">
                <section id="errorView" className="bg-overlay h-100vh overflow-hidden">
                    <div className="container h-100">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="error-area text-center">
                                <h1 className="text-white">{initData.heading}</h1>
                                <p className="text-white my-4">{initData.text}</p>
                                <Button className="btn homeButton text-uppercase" component="a" style={{fontSize: 18}} variant="text" onClick={() => navigate("/")}>{initData.btnText}</Button>
                                {/*<a href="/" className="btn homeButton text-uppercase">{initData.btnText}</a>*/}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ErrorView;
