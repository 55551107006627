import React from 'react';
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from '@mui/material';
import CON from '../../CON';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const DrinkCardV3 = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card style={{width: '70vw', borderRadius: 12, margin: 10, borderColor: "transparent", backgroundColor: 'rgba(255,255,255,0.53)' }} key={props.drink.id} onClick={handleClickOpen}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="160"
                        style={{ objectFit: 'contain', marginTop: 10 }}
                        image={`${props.drink.imageUrl}` || '/path/to/local/fallback/image.png'}
                        alt={props.drink.name}
                    />
                    <CardContent>
                        <Typography style={{fontSize: 15, color: '#000'}} gutterBottom variant="h5">
                            {props.drink.name}
                        </Typography>
                        <Typography style={{fontSize: 15, color: '#000'}} gutterBottom variant="h5">
                            {props.drink.percentage} - {props.drink.type}
                        </Typography>
                        <Typography style={{fontSize: 15, color: '#000'}} gutterBottom variant="h5">
                            {props.drink.description}
                        </Typography>
                        <div>
                            {Object.keys(props.drink?.prices).map((key) => (
                                <div key={key}>
                                    <Typography style={{fontSize: 15, color: '#000'}} gutterBottom variant="h5"
                                                noWrap>
                                        {props.drink?.prices[key]} {key}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="drink-detail-dialog"
                open={open}
                PaperProps={{
                    style: {backgroundColor: 'rgba(255,255,255)'},
                }}
            >
                {/*<DialogTitle>*/}
                {/*    <IconButton*/}
                {/*        aria-label="close"*/}
                {/*        onClick={handleClose}*/}
                {/*        sx={{*/}
                {/*            position: 'absolute',*/}
                {/*            right: 8,*/}
                {/*            top: 0,*/}
                {/*            color: (theme) => theme.palette.grey[500],*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <CloseIcon />*/}
                {/*    </IconButton>*/}
                {/*</DialogTitle>*/}
                <DialogContent dividers>
                    <Box style={{}}>
                        <div style={{ textAlign: 'center' }}>
                            <img style={styles.image} src={`${props.drink.imageUrl}` || '/path/to/local/fallback/image.png'} alt={`${props.drink.name} - ${props.drink.type}`}/>
                        </div>
                        <div>
                            <Typography style={styles.name}>{props.drink.name}</Typography>
                        </div>
                        <div>
                            <Typography style={styles.name}>{props.drink.percentage} - {props.drink.type}</Typography>
                        </div>
                        <div>
                            <Typography style={styles.description}>{props.drink.description}</Typography>
                        </div>
                        <div>
                            {Object.keys(props.drink?.prices).map((key) => (
                                <div key={key}>
                                    <Typography index={key} style={styles.description}>{props.drink?.prices[key]} {key}</Typography>
                                </div>
                            ))}
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const styles = {
    sectionHeader: {
        fontSize: 22,
        fontWeight: '500',
        alignSelf: 'flex-start',
        marginLeft: 15,
        color: '#000',
    },
    background: {
        flex: 1,
        resizeMode: 'cover', // or 'stretch' or 'contain' based on your preference
    },
    name: {
        color: '#000',
        fontWeight: '500',
        fontSize: 17,
        marginBottom: 0,
        marginLeft: 20
    },
    description: {
        color: '#000',
        fontWeight: '500',
        fontSize: 15,
        // textAlign: 'center',
        marginBottom: 0,
        marginLeft: 20
    },
    image: {
        objectFit: 'contain',
        width: '50vw',
        height: '50vw',
        borderRadius: 6,
        overflow: 'hidden',
        alignSelf: 'center',
        marginBottom: 10,
        marginTop: 20
    },
};

export default DrinkCardV3;
