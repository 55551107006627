import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import BrandDetailView from "./BrandDetailView";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BrandView = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button size={"small"} variant="outlined" onClick={handleClickOpen}>
                {getText(languagePreference.lang, 'AM5')}
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='lg'
                aria-labelledby="brand-detail-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="brand-detail-dialog-title" onClose={handleClose}>
                    {getText(languagePreference.lang, 'BM4')}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <BrandDetailView brand={props.brand} />
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} autoFocus onClick={handleClose}>
                        {getText(languagePreference.lang, 'AM29')}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default BrandView;
