
import React from "react";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";

// Views
import ErrorView from "../views/404/ErrorView";
import ResponsiveAppBar from "../components/Navigation/ResponsiveAppBar";
import LandingView from "../views/Landing/LandingView";
import AdminLoginView from "../views/AdminLogin/AdminLoginView";
import LoginView from "../views/Login/LoginView";
import { useDispatch } from "react-redux";
import HomeView from "../views/Home/HomeView";
import SettingsView from "../views/Settings/SettingsView";
import SignUpView from "../views/SignUp/SignUpView";
import ResetPasswordView from "../views/ResetPassword/ResetPasswordView";
import DashboardView from "../views/Dashboard/DashboardView";
import AdminResetPasswordView from "../views/ResetPassword/AdminResetPasswordView";

const AppRoutes = () => {
    const dispatch = useDispatch();

    function RenderAppBar() {
        const location = useLocation();

        // Check if the current path contains a certain value
        const shouldRenderAppBar = location.pathname.includes("admin");

        // Conditionally render the AppBar
        return shouldRenderAppBar ? <ResponsiveAppBar /> : null;
    }

    function MarginTopWrapper() {
        const location = useLocation();

        // Check if the current path contains a certain value
        const hasCertainText = location.pathname.includes("admin");

        // Get marginTop value based on condition
        const marginTop = hasCertainText ? "70px" : "10px";

        return (
            <div style={{ marginTop, overflow: "scroll" }}>
                <Routes>
                    <Route path="/" element={<LandingView />} />
                    <Route path="/admin/dashboard" element={<DashboardView />} />
                    <Route path="/admin/login" element={<AdminLoginView />} />
                    <Route path="/login" element={<LoginView />} />
                    <Route path="/signup" element={<SignUpView />} />
                    <Route path="/reset" element={<ResetPasswordView />} />
                    <Route path="/admin/reset" element={<AdminResetPasswordView />} />
                    <Route path="/home" element={<HomeView />} />
                    <Route path="/settings" element={<SettingsView />} />
                    <Route path="/404" element={<ErrorView />} />
                    <Route path="*" element={<ErrorView />} />
                </Routes>
            </div>
        );
    }

    React.useEffect(() => {

        return () => {
        };
    },[]);

    return (
        <Router>
            <RenderAppBar />
            <MarginTopWrapper />
        </Router>
    );
}

export default AppRoutes;
