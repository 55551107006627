import * as React from 'react';
import Container from '@mui/material/Container';
import background from "../../assets/background.jpg";
import logo from "../../assets/logo_500-01.png";
import {Button, Typography} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useNavigate} from "react-router-dom";

const LandingView = () => {
    const navigate = useNavigate();
    React.useEffect(() => {

    },[]);

    return (
    <Container component="main" style={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
    }} maxWidth="lg">
        <div
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                height: '100%',
                // filter: 'blur(6px)', // Adjust the blur value as needed
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1, // Ensure the background is behind the content
            }}
        />
        <div>
            <img alt={'logo'} src={logo} style={{ marginTop: '10vh', width: '100%', aspectRatio: 3/2, objectFit: 'contain' }} />
        </div>
        <div style={{position: 'absolute', bottom: '22vh'}}>

        </div>
    </Container>
    );
}

const styles = {
    container: {
        flex: 1,
    },
    header: {
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        flex: 1,
        backgroundColor: '#fff',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        paddingVertical: 50,
        paddingHorizontal: 30,
    },
    background: {
        flex: 1,
        resizeMode: 'cover', // or 'stretch' or 'contain' based on your preference
        justifyContent: 'center', // Align children components vertically in the center
    },
    logo: {
        width: '15%',
        aspectRatio: 1/2.5,
        objectFit: 'contain'
    },
    title: {
        color: '#000',
        fontSize: 28,
        fontWeight: 'bold',
        width: '90%'
    },
    text: {
        color: '#000',
        marginTop: 5,
    },
    button: {
        position: 'absolute',
        right: '10vw',
        top: '25vh',
    },
    signIn: {
        color: '#494949',
        width: 150,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        flexDirection: 'row',
        fontWeight: 'bold',
        backgroundColor: '#f8f8f8',
    },
    textSign: {
        color: '#494949',
        fontWeight: 'bold',
    },
    sectionHeader: {
        fontSize: 25,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginLeft: 15,
        color: '#ffffff',
    },
};

export default LandingView;
