import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import CreateCategory from "./CreateCategory";
import Grid from "@mui/material/Grid";
import DataGridTable from "../DataGridTable";
import ProductView from "./ProductView";


const CategoryTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const categories = useSelector(state => state.categoryManager.categories);

    const getStatusLabel = (status) => {
        switch (status) {
            case "ARCHIVE":
                return getText(languagePreference.lang, 'PCM7');
            case "VISIBLE":
                return getText(languagePreference.lang, 'PCM8');
            case "HIDDEN":
                return getText(languagePreference.lang, 'PCM9');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const dataGridOption = {
        rows: categories || [],
        columns: [
            { field: 'categoryID', headerName: 'ID', hide: false, width: 50 },
            { field: 'name', headerName: 'Name', valueGetter: (params) => `${params.row?.name}`, hide: false, width: 200 },
            { field: 'parentName', headerName: 'Parent Category', valueGetter: (params) => `${params.row.parentName !== null ? params.row.parentName : "---"}`, hide: false, width: 130 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row?.status), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 200,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <ProductView product={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.categoryID,
        onRowClick: () => {
            // params, event

        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    useEffect(() => {

    }, []);

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreateCategory />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default CategoryTable;
