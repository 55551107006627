import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import CreateAccount from "./CreateAccount";
import {useEffect} from "react";
import AccountDetailModal from "./AccountDetailModal";
import Grid from "@mui/material/Grid";
import DataGridTable from "../DataGridTable";

const UsersTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const accounts = useSelector(state => state.accountManager.accounts);

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return getText(languagePreference.lang, 'AM23');
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const dataGridOption = {
        rows: accounts || [],
        columns: [
            { field: 'accountID', headerName: 'ID', hide: false, width: 50 },
            { field: 'firstname', headerName: 'Firstname', valueGetter: (params) => `${params.row?.firstname}`, hide: false, width: 150 },
            { field: 'lastname', headerName: 'Lastname', valueGetter: (params) => `${params.row?.lastname}`, hide: false, width: 150 },
            { field: 'role', headerName: 'Brand', valueGetter: (params) => `${params.row.role !== null ? params.row.role[0].roleName : 'Receptionist'}`, hide: false, width: 160 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row?.isActive), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 100,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <AccountDetailModal account={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.accountID,
        onRowClick: () => {
            // params, event

        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    useEffect(() => {
        // if (accounts.length <= 0){
        //     AccountService.getAllAccounts().then((response) =>{
        //         if (response.status === true){
        //             dispatch(setAccounts({
        //                 accounts: response.accounts
        //             }));
        //         } else {
        //             // show error could not get data
        //         }
        //     }).catch((err) => {
        //         console.log(err);
        //     });
        // }
    });

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreateAccount />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default UsersTable;
