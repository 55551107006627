import { createSlice } from '@reduxjs/toolkit'

export const locationReducer = createSlice({
    name: 'locationReducer',
    initialState: {
        locations: [],
        tapLists: [],
        selectedLocation: 0,
    },
    reducers: {
        setTapLists: (state, action) => {
            state.tapLists = action.payload.tapLists;
        },
        setLocations: (state, action) => {
            state.locations = action.payload.locations;
        },
        setSelectedLocations: (state, action) => {
            state.selectedLocation = action.payload.selectedLocation;
        },
    },
})

export const { setLocations, setTapLists,setSelectedLocations } = locationReducer.actions

export default locationReducer;
