import * as React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import CreatePromotion from "./CreatePromotion";
import PromotionView from "./PromotionView";
import DataGridTable from "../DataGridTable";
import Grid from "@mui/material/Grid";

const PromotionTable = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const promotions = useSelector(state => state.promotionManager.promotions);

    const getRewardTypeLabel = (status) => {
        switch (status) {
            case "FREE_ITEM":
                return "Free Item";
            case "BUY_ONE_GET_ONE":
                return "Buy One Get One";
            case "BUY_ONE_GET_X_OFF":
                return "BUY_ONE_GET_X_OFF";
            case "VARIABLE_DISCOUNT":
                return "Variable Discount";
            case "EXACT_DISCOUNT":
                return "Exact Discount";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "ACTIVE":
                return "Active";
            case "INACTIVE":
                return "Inactive";
            case "DELETED":
                return "Deleted";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    const dataGridOption = {
        rows: promotions || [],
        columns: [
            { field: 'promotionID', headerName: 'ID', hide: false, width: 50 },
            { field: 'name', headerName: 'Name', valueGetter: (params) => `${params?.row.name}`, hide: false, width: 200 },
            { field: 'rewardType', headerName: 'Reward Type', valueGetter: (params) => getRewardTypeLabel(params.row.rewardType), hide: false, width: 130 },
            { field: 'duration', headerName: 'duration', valueGetter: (params) => `${params.row.startDate.split("T")[0].split('-').join('/')} - ${params.row.endDate ? params.row.endDate.split("T")[0].split('-').join('/') : 'N/A'}`, hide: false, width: 200 },
            { field: 'status', headerName: 'Status', hide: false, valueGetter: (params) => getStatusLabel(params.row?.status), width: 100 },
            {
                field: 'action',
                headerName: 'Actions',
                hide: false,
                hideable: false,
                filterable: false,
                width: 200,
                type: 'actions',
                getActions: (param) => {
                    return [
                        <PromotionView promotion={param.row} />
                    ];
                }
            },
        ],
        componentsProps: {
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
            },
        },
        showCellVerticalBorder: false,
        showColumnVerticalBorder: false,
        disableColumnFilter: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableMultipleRowSelection: true,
        disableRowSelectionOnClick: true,
        density: 'compact',
        getRowId: (row) => row.promotionID,
        onRowClick: () => {
            // params, event

        },
        onCellClick: () => {
            // params, event
        },
        onRowSelectionModelChange: async (rowSelectionModel) => {

        },
        columnBuffer: 10,
        columnThreshold: 10,
        style: {
            width: '70vw',
        },
        height: 500,
        isLocked: false
    };

    useEffect(() => {

    }, []);

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <Grid container spacing={2}>
                <Grid item sx={12}>
                    <CreatePromotion />
                </Grid>
                <Grid item sx={12}>
                    <DataGridTable dataGridOption={dataGridOption}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default PromotionTable;
