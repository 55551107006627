import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import ProductTable from "./ProductTable";
import ProductTableFilter from "./ProductTableFilter";
import ProductService from "../../services/productService";
import CategoryTable from "./CategoryTable";
import CategoryService from "../../services/categoryService";
import CategoryTableFilter from "./CategoryTableFilter";
import MetaTable from "./MetaTable";
import MetaTableFilter from "./MetaTableFilter";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StockManagement = () => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const stores = useSelector(state => state.storeManager.stores);
    const products = useSelector(state => state.productManager.products);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchTermM, setSearchTermM] = React.useState('');
    const [searchTermC, setSearchTermC] = React.useState('');

    const [searchResults, setSearchResults] = React.useState(null);
    const [searchResultsC, setSearchResultsC] = React.useState(null);
    const [searchResultsM, setSearchResultsM] = React.useState(null);

    const [value, setValue] = React.useState(0);

    const [type, setType] = React.useState("");


    const handleSearch = (name, searchType) => {
        switch (searchType) {
            case 'PRODUCT':
                if (name !== ''){
                    ProductService.searchByName(name).then((response) =>{
                        if (response.status === true){
                            setSearchResults(response.results);
                        } else {
                            setSearchResults([]);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    setSearchResults(null);
                }
                break;
            case 'CATEGORY':
                if (name !== ''){
                    CategoryService.searchByName(name).then((response) =>{
                        if (response.status === true){
                            setSearchResultsC(response.results);
                        } else {
                            setSearchResultsC([]);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    setSearchResultsC(null);
                }
                break;
            case 'META':
                if (name !== ''){
                    // MetaService.searchByName(name).then((response) =>{
                    //     if (response.status === true){
                    //         setSearchResultsM(response.results);
                    //     } else {
                    //         setSearchResultsM([]);
                    //     }
                    // }).catch((err) => {
                    //     console.log(err);
                    // });
                } else {
                    setSearchResultsM(null);
                }
                break;
            default:
                return;
        }
    };

    useEffect(() => {

    },[]);

    useMemo(() => {
        let lst = [];
        for (let i = 0; i < products.length; i++) {
            if (type.name === products[i].category.name){
                lst.push(products[i]);
            }
        }
        if (lst.length > 0){
            setSearchResults(lst);
        } else {
            setSearchResults(products);
        }
    }, [type]);

    useMemo(() => {
        console.log("****************************")
        console.log(searchTerm)
        console.log("****************************")
        if (searchTerm !== ''){
            handleSearch(searchTerm, 'PRODUCT');
        } else {
            let lst = [];
            for (let i = 0; i < products.length; i++) {
                if (type.name === products[i].category.name){
                    lst.push(products[i]);
                }
            }
            if (lst.length > 0){
                setSearchResults(lst);
            } else {
                setSearchResults(products);
            }
        }
    }, [products]);



    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(event, value) => {setValue(value)}} aria-label="stock management">
                    <Tab label={getText(languagePreference.lang, 'PCM1')} {...a11yProps(0)} />
                    <Tab label={getText(languagePreference.lang, 'PCM2')} {...a11yProps(1)} />
                    <Tab label={getText(languagePreference.lang, 'PCM15')} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/*<ProductTableFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />*/}
                <ProductTable searchResults={searchResults} type={type} setType={setType} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/*<CategoryTableFilter searchTerm={searchTermC} setSearchTerm={setSearchTermC} handleSearch={handleSearch} />*/}
                <CategoryTable searchResults={searchResultsC} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/*<MetaTableFilter searchTerm={searchTermM} setSearchTerm={setSearchTermM} handleSearch={handleSearch} />*/}
                <MetaTable searchResults={searchResultsM} />
            </TabPanel>
        </Box>
    );
}

export default StockManagement;
