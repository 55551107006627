import React from 'react';
import {Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography} from '@mui/material';
import CON from '../../CON';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useSelector} from "react-redux";
import DrinkCardV2 from "../Drinks/DrinkCardV2";
import background from "../../assets/background.jpg";

const CategoryCard = (props) => {
    const [open, setOpen] = React.useState(false);
    const drinks = useSelector(state => state.drinkManager.drinks);
    const locations = useSelector(state => state.locationManager.locations);
    const selectedLocation = useSelector(state => state.locationManager.selectedLocation);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button style={{width: '90vw', overflow: 'hidden', height: 135, marginBottom: 25, borderRadius: 20}} onClick={handleClickOpen}>
                <img style={styles.image} src={`${CON.IMAGE_BASE_URL}${props.category.background}` || '/path/to/local/fallback/image.png'} alt={props.category.name} />
                <Typography style={styles.name} gutterBottom variant="h5" component="div" noWrap>
                    {props.category.name}
                </Typography>
            </Button>
            <Dialog
                onClose={handleClose}
                fullScreen
                aria-labelledby="drink-detail-dialog"
                open={open}
                PaperProps={{
                    style: { backgroundColor: 'unset' },
                }}
                style={{ backgroundColor: 'unset' }}
            >
                <AppBar style={{ position: 'relative', backgroundColor: '#9c9a9a' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.category.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <div
                        style={{
                            backgroundImage: `url(${background})`,
                            backgroundSize: 'cover',
                            height: '100%',
                            filter: 'blur(6px)', // Adjust the blur value as needed
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: -1, // Ensure the background is behind the content
                        }}
                    />
                    {drinks.map((item, index) => {
                        if (props.category.categoryID === item.category.categoryID) {
                            if (locations.length > 0){
                                if (locations[selectedLocation].storeID === item.stock[0]?.store?.storeID){
                                    return (
                                        <DrinkCardV2 key={index} drink={item} />
                                    );
                                }
                            } else {
                                return (
                                    <DrinkCardV2 key={index} drink={item} />
                                );
                            }
                        }
                    })}
                </DialogContent>
            </Dialog>
        </div>
    );
};

const styles = {
    container: {
        padding: 0,
        width: '90vw',
        height: '25vw',
        marginBottom: 30,
        borderRadius: 6,
        borderColor: "transparent",
        backgroundColor: 'transparent',
    },
    image: {
        objectFit: 'contain',
        // height: '30vw',
        borderRadius: 12,
        overflow: 'hidden',
    },
    header: {
        fontWeight: '500',
        fontSize: 15,
        position: 'absolute',
        top: '40%',
        left: 10,
        textAlignVertical: 'center',
        textAlign: 'center',
    },
    name: {
        position: "absolute",
        fontSize: 15,
        top: 5,
        left: 15,
        fontWeight: 'bold',
        color: "#fff"
    }
};

export default CategoryCard;
