import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import {Divider, FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CustomPermissionList from "./CustomPermissionList";
import {useEffect} from "react";
import AccountService from "../../services/accountService";
import RoleInfoCard from "./RoleInfoCard";
import {setRoles} from "../../reducers/accountReducer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AccountCreationRolesPermission = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);
    const roles = useSelector(state => state.accountManager.roles);
    const dispatch = useDispatch();


    const [justView, setJustView] = React.useState(false);

    const [permissionsSelect, setPermissionsSelect] = React.useState(null);

    // const [roles, setRoles] = React.useState(null);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        console.log(roles)
        if (permissionsSelect === null) {
            AccountService.getPermissionsSelect().then((response) =>{
                if (response.status === true){
                    setPermissionsSelect(response.permissions);
                } else {
                    // show error could not get data
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <div>
            {roles &&
                (roles.map((role, index) => {
                    return (
                        <RoleInfoCard key={index} role={role} selectedRole={props.selectedRole} setSelectedRole={props.setSelectedRole} justView={justView} />
                    );
                }))
            }


            {/*<FormControl fullWidth style={{marginBottom: 15, minWidth: 400}}>*/}
            {/*    <InputLabel id="account-role-permission-label">Account Type</InputLabel>*/}
            {/*    <Select labelId="account-role-permission-label" id="account-role-permission-select" value={props.accountType}*/}
            {/*            label="User Type" onChange={(event) => {*/}
            {/*                props.setAccountType(event.target.value);*/}
            {/*                props.setAccountPermissions([]);*/}
            {/*                props.setSelectedRole(null);*/}
            {/*            }}*/}
            {/*    >*/}
            {/*        <MenuItem value={'MASTER'}>Master</MenuItem>*/}
            {/*        <MenuItem value={'ADMIN'}>Admin</MenuItem>*/}
            {/*        <MenuItem value={'EMPLOYEE'}>Employee</MenuItem>*/}
            {/*        <MenuItem value={'USER'}>User</MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}

            {/*<Divider />*/}
            {/*<Box sx={{ flexGrow: 1, display: 'flex', minHeight: 400 }}>*/}
            {/*    <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange}*/}
            {/*          aria-label="Account Creation Roles Permission Tabs" sx={{ borderRight: 1, borderColor: 'divider', maxWidth: '300px' }}*/}
            {/*    >*/}
            {/*        <Tab label={'Assign By Role'} {...a11yProps(0)} />*/}
            {/*        <Tab label={'Assign Custom Permissions'} {...a11yProps(1)} />*/}
            {/*    </Tabs>*/}
            {/*    <TabPanel value={value} index={0}>*/}

            {/*        {roles !== null &&*/}
            {/*            (roles.map((role, index) => {*/}
            {/*                if (props.accountType === 'USER'){*/}
            {/*                    if (role.roleID === 5){*/}
            {/*                        return (*/}
            {/*                            <RoleInfoCard key={index} role={role} selectedRole={props.selectedRole} setSelectedRole={props.setSelectedRole} justView={justView} />*/}
            {/*                        );*/}
            {/*                    }*/}
            {/*                } else if (props.accountType === 'EMPLOYEE'){*/}
            {/*                    if (role.roleID === 3 || role.roleID === 4){*/}
            {/*                        return (*/}
            {/*                            <RoleInfoCard key={index} role={role} selectedRole={props.selectedRole} setSelectedRole={props.setSelectedRole} justView={justView} />*/}
            {/*                        );*/}
            {/*                    }*/}
            {/*                } else if (props.accountType === 'ADMIN'){*/}
            {/*                    if (role.roleID === 2){*/}
            {/*                        return (*/}
            {/*                            <RoleInfoCard key={index} role={role} selectedRole={props.selectedRole} setSelectedRole={props.setSelectedRole} justView={justView} />*/}
            {/*                        );*/}
            {/*                    }*/}
            {/*                } else if (props.accountType === 'MASTER'){*/}
            {/*                    return (*/}
            {/*                        <RoleInfoCard key={index} role={role} selectedRole={props.selectedRole} setSelectedRole={props.setSelectedRole} justView={justView} />*/}
            {/*                    );*/}
            {/*                }*/}
            {/*            }))*/}
            {/*        }*/}


            {/*    </TabPanel>*/}
            {/*    <TabPanel value={value} index={1}>*/}
            {/*        {permissionsSelect !== null &&*/}
            {/*            <div style={{textAlign: "center"}}>*/}
            {/*                {permissionsSelect.map((section, index) => {*/}
            {/*                    return (*/}
            {/*                        <div key={index} style={{display: "inline-block", marginRight: 20}}>*/}
            {/*                            <Typography style={{fontWeight: "bold", marginLeft: 15, fontSize: 20, textAlign: "left"}}>{section.header}</Typography>*/}
            {/*                            <CustomPermissionList data={section.permissions} accountPermissions={props.accountPermissions} setAccountPermissions={props.setAccountPermissions} />*/}
            {/*                        </div>*/}
            {/*                    );*/}
            {/*                })}*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    </TabPanel>*/}
            {/*</Box>*/}
        </div>
    );
}


export default AccountCreationRolesPermission;
