import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import getText from "../../language/getText";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {setStores, setZipCodes} from "../../reducers/storeReducer";
import StoreService from "../../services/storeService";
import StoreTable from "./StoreTable";
import StoreTableFilter from "./StoreTableFilter";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StoreManagement = () => {
    const dispatch = useDispatch();

    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState(null);

    const [value, setValue] = React.useState(0);

    const handleSearch = (name) => {
        if (name !== ''){
            StoreService.searchByName(name).then((response) =>{
                if (response.status === true){
                    setSearchResults(response.results);
                } else {
                    setSearchResults([]);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setSearchResults(null);
        }
    };

    useEffect(() => {
        let enabled = [];
        let disabled = [];
        StoreService.getAllZipCodes().then((response) =>{
            if (response.status === true){
                for (let i = 0; i < response.results.length; i++) {
                    if (response.results[i].status === 'ENABLED'){
                        enabled.push(response.results[i]);
                    } else if (response.results[i].status === 'DISABLED'){
                        disabled.push(response.results[i]);
                    }
                }
                dispatch(setZipCodes({
                    zipCodes: {
                        enabled: enabled,
                        disabled: disabled
                    }
                }));
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(event, value) => {setValue(value)}} aria-label="store management">
                    <Tab label={getText(languagePreference.lang, 'SM3')} {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/*<StoreTableFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />*/}
                <StoreTable searchResults={searchResults} />
            </TabPanel>
        </Box>
    );
}

export default StoreManagement;
