import React from 'react';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import MetaService from "../../services/metaService";
import {updateAccount} from "../../reducers/accountReducer";
import AccountService from "../../services/accountService";
import {useDispatch} from "react-redux";
import StoreSelect from "../Store/StoreSelect";
import StoreService from "../../services/storeService";
import {updateStore} from "../../reducers/storeReducer";


const ChangeStoreValue = (props) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [newValue, setNewValue] = React.useState('');
    const [newValueError, setNewValueError] = React.useState(false);
    const [newValueErrorMsg, setNewValueErrorMsg] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const handleSetNewValue = (event) => {
        setNewValue(event.target.value);
    };

    const clearInputs = () => {
        setNewValue('');
    }

    const handleNewValueChange = () => {
        if (newValue !== ''){
            switch (props.type) {
                case 'STATUS':
                    StoreService.changeStatus(props.store.storeID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateStore({
                                    store: {
                                        ...props.store,
                                        status: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'NAME':
                    StoreService.changeName(props.store.storeID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateStore({
                                    store: {
                                        ...props.store,
                                        name: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                case 'MEMBER_TYPE':
                    AccountService.changeMembershipType(props.account.accountID, newValue).then(response => {
                        if (response){
                            if (response.status === true){
                                dispatch(updateAccount({
                                    account: {
                                        ...props.account,
                                        membershipType: newValue
                                    }
                                }));
                                showAlertMsg(response.message, 'success');
                                clearInputs();
                                handleClose();
                            } else {
                                showAlertMsg(response.message, 'error');
                            }
                        } else {
                            showAlertMsg("Sorry, something went wrong! Try to log out and back in.", 'error');
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                default:
                    break;
            }
        } else {
            showAlertMsg('Must enter a value to proceed with change!', 'error');
        }
    };

    const handleStatusChange = (newStatus) => {

    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearInputs();
    };

    const getChangeLabel = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    `You are about to change the name ${props.store.name}`
                );
            case 'STATUS':
                return (
                    `You are about to change the status ${props.store.status === 1 ? "Active" : props.store.status === 0 ? "Inactive" : "---"}`
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const getChangeContent = () => {
        switch (props.type) {
            case 'NAME':
                return (
                    <TextField
                        color={"primary"}
                        autoFocus
                        margin="dense"
                        id="newValue"
                        value={newValue}
                        error={newValueError} helperText={newValueErrorMsg} onChange={handleSetNewValue}
                        label="New First Name"
                        fullWidth
                    />
                );
            case 'STATUS':
                return (
                    <div style={{textAlign: "center"}}>
                        <Button style={{marginLeft: "10px"}} variant="contained"  color="primary"  onClick={() => setNewValue(1)} disabled={((newValue !== '' ? newValue : props.store.status) === 1)} >
                            Active
                        </Button>

                        <Button style={{marginLeft: "10px"}} variant="contained" color="primary" onClick={() => setNewValue(0)} disabled={((newValue !== '' ? newValue : props.store.status) === 0)} >
                            Inactive
                        </Button>
                    </div>
                );
            default:
                return (
                    `Unknown change type used.`
                );
        }
    };

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    return (
        <div>
            <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} startIcon={<EditIcon />}>
                Change
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Account Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity={alertSeverity}>{alertMsg}</Alert>
                        )}
                        {getChangeLabel()}
                    </DialogContentText>
                    {getChangeContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleNewValueChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangeStoreValue;
